import { useState, useEffect } from "react";
import { format } from "date-fns";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  CardHeader,
  Dialog,
  Loading,
} from "../../../../components";
import {
  OrcamentosService,
  OperacaoFiscalService,
  PessoaService,
  history,
  OrigemMercadoriaService,
  notification,
  UnidadeComercialService,
  ProdutosService,
} from "../../../../services";
import { responseErros, formatDate, formatPrice } from "../../../../utils";
import { OrcamentoStepperContent } from ".";
import { NfSaida, NfSaidaItem } from "../../entities";
import {
  formatProdutosTotalizadoresDto,
  totalVenda,
} from "../../add-nf-saida/add-nf-saida";

const ImportarOrcamentoDialog = ({
  openImportarOrcamentoDialog,
  setOpenImportarOrcamentoDialog,
  filtrosDialog,
  setFiltrosDialog,
}) => {
  const orcamentosService = new OrcamentosService();
  const operacaoFiscalService = new OperacaoFiscalService();
  const pessoaService = new PessoaService();
  const unidadeComercialService = new UnidadeComercialService();
  const origemMercadoriaService = new OrigemMercadoriaService();
  const produtosService = new ProdutosService();

  const [loading, setLoading] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [orcamentos, setOrcamentos] = useState([]);
  const [orcamentosSelecionados, setOrcamentosSelecionados] = useState([]);
  const [orcamentoSelectionModel, setOrcamentoSelectionModel] = useState([]);
  const [itemSelectionModel, setItemSelectionModel] = useState([]);
  const [itensSelecionados, setItensSelecionados] = useState([]);
  const [totalListaOrcamentos, setTotalListaOrcamentos] = useState(0);
  const [unidadeList, setUnidadeList] = useState([]);
  const [origensMercadoriaList, setOrigensMercadoriaList] = useState([]);
  const [listaPessoas, setListaPessoas] = useState([]);
  const [listaVendedores, setListaVendedores] = useState([]);
  const [listaOperacoesFiscais, setListaOperacoesFiscais] = useState([]);
  const steps = ["Selecionar Orçamentos", "Selecionar Itens e Importar"];
  const colunas = [
    {
      field: "serieDocumento",
      headerName: "Série",
      width: 90,
    },
    {
      field: "numeroDocumento",
      headerName: "Nº Doc",
      width: 105,
    },
    {
      field: "dataEmissao",
      headerName: "Data de Emissão",
      width: 170,
      valueGetter: (params) =>
        format(formatDate.received(params.value), "dd/MM/yyyy"),
    },
    {
      field: "operacaoFiscalId",
      headerName: "Operação Fiscal",
      width: 250,
      valueGetter: (params) =>
        listaOperacoesFiscais.find((item) => item.id == params.value)
          ?.descricao,
    },
    {
      field: "pessoaClienteId",
      headerName: "Cliente",
      width: 170,
      valueGetter: (params) =>
        listaPessoas.find((item) => item.id == params.value)?.nomeRazaoSocial,
    },
    {
      field: "pessoaVendedorId",
      headerName: "Vendedor",
      width: 250,
      valueGetter: (params) =>
        listaVendedores.find((item) => item.id == params.value)
          ?.nomeRazaoSocial,
    },
    {
      field: "itens",
      headerName: "Total do Orçamento",
      width: 250,
      valueGetter: (params) => {
        const totalOrcamento =
          params.value
            .map((item) => item.valorUnitario * item.saldoExportar)
            .reduce((acumulador, total) => acumulador + total, 0) +
          params.row.acrescimo -
          params.row.desconto +
          params.row.despesas;
        return formatPrice(totalOrcamento);
      },
    },
  ];

  useEffect(() => {
    if (openImportarOrcamentoDialog) {
      buscarOrcamentos();
      buscarUnidadesComerciais();
      buscarOrigensMercadoria();
    }
  }, [openImportarOrcamentoDialog]);

  const buscarOrcamentos = async (filtro) => {
    setLoading(true);
    const filtros = {
      ...filtro,
      status: ["ABERTO", "PARCIALMENTE_FECHADO"],
    };
    const result = await orcamentosService.getAllFiltroAvancado(filtros);
    if (!result.isAxiosError) {
      setTotalListaOrcamentos(result.data.count);
      setOrcamentos(result.data.rows);
      buscarRelacionamentosOrcamento(result.data.rows);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const buscarOrcamentosAvancado = async (filtros) => {
    setLoading(true);
    const porIntervalo = [];
    const filtrosAvancadosTemp = {
      ...filtros,
      status: ["ABERTO", "PARCIALMENTE_FECHADO"],
      restritiva: true,
    };
    if (filtrosAvancadosTemp.dataInicial && filtrosAvancadosTemp.dataFinal) {
      porIntervalo.push({
        coluna: "dataEmissao",
        de: filtrosAvancadosTemp.dataInicial,
        ate: filtrosAvancadosTemp.dataFinal,
      });
    }
    delete filtrosAvancadosTemp.dataInicial;
    delete filtrosAvancadosTemp.dataFinal;
    const result = await orcamentosService.getAllFiltroAvancado({
      ...filtrosAvancadosTemp,
      porIntervalo,
    });
    if (!result.isAxiosError) {
      setOrcamentos(result.data.rows);
      setTotalListaOrcamentos(result.data.rows.length);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const buscarRelacionamentosOrcamento = (listaOrcamento) => {
    const idsPessoas = [];
    const idsOperacoesFiscais = [];
    const idsVendedores = [];
    for (const objeto of listaOrcamento) {
      if (objeto.pessoaClienteId) idsPessoas.push(objeto.pessoaClienteId);
      if (objeto.pessoaVendedorId) idsVendedores.push(objeto.pessoaVendedorId);
    }
    buscarOperacoesFiscais([...new Set(idsOperacoesFiscais)]);
    buscarPessoas([...new Set(idsPessoas)]);
    buscarVendedores([...new Set(idsVendedores)]);
  };

  const buscarOperacoesFiscais = (listaIds) => {
    const filtro = {
      id: listaIds.length > 0 ? listaIds : undefined,
      nonPaginated: true,
    };
    operacaoFiscalService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setListaOperacoesFiscais(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarPessoas = (listaIds) => {
    const filtro = {
      id: listaIds.length > 0 ? listaIds : undefined,
      nonPaginated: true,
    };
    pessoaService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        const listaPessoas = result.data;
        setListaPessoas(listaPessoas);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarVendedores = (listaIds) => {
    const filtro = {
      id: listaIds.length > 0 ? listaIds : undefined,
      nonPaginated: true,
      vendedor: true,
      tiposPessoaId: 4,
    };
    pessoaService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setListaVendedores(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const handleDateChange = (date, fieldName) => {
    setFiltrosDialog((prevState) => ({
      ...prevState,
      [fieldName]: date != "Invalid Date" ? formatDate.toSend(date) : null,
    }));
  };

  const handleInputChange = (e) => {
    e.persist();
    setFiltrosDialog((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleImportarOrcamento = async () => {
    if (!itemSelectionModel?.length)
      return notification.alertaGenericos(
        "Selecione ao menos um produto para importar"
      );
    window.nfSaidaEntity = await createNfSaida(
      orcamentosSelecionados[0],
      orcamentosSelecionados,
      itensSelecionados
    );
    setOpenImportarOrcamentoDialog(false);
    resetarDadosImportacao();
    history.push("/faturamento/nf-saida/importar-orcamento");
  };

  const getOperacaoFiscal = async (id) => {
    if (!id) return;
    return operacaoFiscalService.getById(id).then((result) => {
      if (!result.isAxiosError) {
        return result.data;
      } else {
        responseErros(result);
        return null;
      }
    });
  };

  const buscarOrigensMercadoria = async () => {
    origemMercadoriaService.getAll().then((res) => {
      if (!res.isAxiosError) {
        setOrigensMercadoriaList(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const buscarUnidadesComerciais = async () => {
    const filtros = {
      nonPaginated: true,
    };
    unidadeComercialService.getAll(filtros).then((result) => {
      if (!result.isAxiosError) {
        setUnidadeList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const createNfSaida = async (orcamento, orcamentos, itens) => {
    const operacaoFiscal = await getOperacaoFiscal(orcamento.operacaoFiscalId);
    if (
      operacaoFiscal?.modeloDocumento == "055" ||
      operacaoFiscal?.modeloDocumento == "065"
    ) {
      return notification.alertaGenericos(
        "Operação fiscal sem regra de tributação associada."
      );
    }
    const itensTemp = await buscarDadosProdutos(
      formatProdutosTotalizadoresDto(
        itens,
        totalVenda(orcamento, itens),
        orcamento
      )
    );
    const nfSaidaItens = itensTemp
      .filter((item) => itemSelectionModel.indexOf(item.id) > -1)
      .map((item, index) => {
        const nfSaidaItem = new NfSaidaItem(
          item,
          index,
          operacaoFiscal,
          origensMercadoriaList,
          unidadeList
        );
        return {
          ...nfSaidaItem,
          produto: item.produto,
          valor: parseFloat(item.valorUnitario.toFixed(2)),
          subtotal:
            parseFloat(item.valorUnitario.toFixed(2)) * item.saldoExportar,
        };
      });
    const informacoesAdicionais = orcamentos
      .map((pedido) => {
        return `Importado do orçamento ${pedido.serieDocumento}/${pedido.numeroDocumento}`;
      })
      .join("\n");
    let cliente = {};
    if (orcamento.pessoaClienteId) {
      cliente = listaPessoas.find(
        (item) => item.id == orcamento.pessoaClienteId
      );
    }
    const dadosNfSaida = {
      dataEmissao: formatDate.toSend(new Date()),
      modalidadeFrete: "mfSemFrete",
      freteCompoeNota: false,
      orcamentoImport: true,
      informacoesAdicionais,
      orcamentoIds: orcamentos.map((orcamento) => orcamento.id),
      clienteId: orcamento?.pessoaClienteId ?? undefined,
      clienteEmail: cliente?.email,
      clienteCnpjCpf: cliente?.cnpjCpf,
      clienteNome: cliente?.nomeRazaoSocial,
      vendedorId: orcamento?.pessoaVendedorId ?? undefined,
      prestadorServicoId: orcamento.pessoaProficionalId,
      operacaoFiscal,
      operacaoFiscalId: operacaoFiscal?.id,
      serieDocumento: operacaoFiscal?.serieDocumento,
      modeloDocumento: operacaoFiscal?.modeloDocumento,
      meioPagamentoId: orcamento.meioPagamentoId,
      condicaoPagamentoId: orcamento.condicaoPagamentoId,
      desconto: orcamento.desconto ?? 0,
      acrescimo: orcamento.acrescimo ?? 0,
      despesas: orcamento.despesas ?? 0,
      nfSaidaItens,
    };
    return new NfSaida(dadosNfSaida, true);
  };

  async function buscarDadosProdutos(itens) {
    const listaIds = itens.map(({ produtoId }) => produtoId);
    const res = await produtosService.getAll({
      id: listaIds,
      nonPaginated: true,
    });
    if (!res.isAxiosError) {
      const produtosMap = new Map(
        res.data.map((produto) => [produto.id, produto])
      );
      return itens.map((item) => ({
        ...item,
        ...produtosMap.get(item.produtoId),
        id: item.id,
      }));
    } else {
      responseErros(res);
    }
  }

  const resetarDadosImportacao = () => {
    setFiltrosDialog({});
    setOrcamentosSelecionados([]);
    setActiveStep(0);
  };

  const handleCancelarImportacao = () => {
    resetarDadosImportacao();
    setOpenImportarOrcamentoDialog(false);
  };

  const sendServerDatagrid = (props) => {
    if (props.tipoFiltro == "simples") {
      delete props.filtros.status;
      buscarOrcamentos(props.filtros);
    } else {
      buscarOrcamentosAvancado(props.filtros);
    }
  };

  return (
    <Dialog fullWidth maxWidth={"md"} open={openImportarOrcamentoDialog}>
      <CardHeader title="Importar Orçamento" className="m-2" />
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <OrcamentoStepperContent
        sendServer={sendServerDatagrid}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        handleImportarOrcamento={handleImportarOrcamento}
        handleCancelarImportacao={handleCancelarImportacao}
        handleDateChange={handleDateChange}
        handleInputChange={handleInputChange}
        filtrosDialog={filtrosDialog}
        setFiltrosDialog={setFiltrosDialog}
        colunas={colunas}
        orcamentos={orcamentos}
        totalListaOrcamentos={totalListaOrcamentos}
        orcamentosSelecionados={orcamentosSelecionados}
        setOrcamentosSelecionados={setOrcamentosSelecionados}
        itensSelecionados={itensSelecionados}
        setItensSelecionados={setItensSelecionados}
        buscarOrcamentosAvancado={buscarOrcamentosAvancado}
        listaClientes={listaPessoas}
        itemSelectionModel={itemSelectionModel}
        setItemSelectionModel={setItemSelectionModel}
        orcamentoSelectionModel={orcamentoSelectionModel}
        setOrcamentoSelectionModel={setOrcamentoSelectionModel}
      />
      <Loading loading={loading} />
    </Dialog>
  );
};

export default ImportarOrcamentoDialog;
